<template>
  <div class="page home">
    <div class="bg-white-3 p-bottom-2">
      <WidgetSiteHeader :page="page" :showEvents="true" />

      <PagesHomeDividerText class="p-top-3" :text="page.pageText" />

      <!-- <div class="p-top-3 p-bottom-2">
        <WidgetReviewSlider :slides="page.reviews" />
      </div> -->
    </div>

    <WidgetStickySections
      class="p-top-2"
      :sections="page.sections"
      :activity="'section'"
      :title="$t('general.section-list-title')"
    />
  </div>
</template>

<script>
export default {
  layout: 'default',
}
</script>

<script setup>
import queries from '@/graphql/queries/index.js'

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(queries.home.getHome, variables)
if (!data.value?.home) {
  throw createError({ statusCode: 404 })
}

const page = computed(() => data.value?.home)

// Seo
const seo = computed(
  () =>
    ({
      ...page?.value.seo,
      meta: [
        {
          name: 'description',
          content: page.value?.seo?.description,
        },
      ],
    }) || {},
)

useSeo(seo)
</script>
